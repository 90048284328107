function toogleClass() {
    var ele = document.getElementById('newmenuarea');
    ele.classList.toggle('showonmobile');
    ele.classList.toggle('active');
}


document.querySelectorAll('.responsive-menu-wraper-primary ul ul').forEach(function (element) {
    var e = document.createElement("li");
    e.classList.add("mobile-back");
    var t = document.createTextNode("<< Zurück");
    e.appendChild(t);
    element.prepend(e);
});

document.querySelectorAll('.mobile-right').forEach(function (button) {
    button.addEventListener("click", displayChild);
});

document.querySelectorAll('.mobile-back').forEach(function (button) {
    button.addEventListener("click", goBack);
});

function displayChild(event) {

    var targetElement = event.target || event.srcElement;
    targetElement.parentElement.querySelector('ul').classList.toggle("goleft");

}

function goBack(event) {

    var targetElement = event.target || event.srcElement;
    targetElement.parentElement.classList.toggle("goleft");

}

var responsiveMobileMenu = document.querySelector('.resposivemenu-primary');
var menuButtonMobile = document.querySelector('#responsivemenu');

document.body.addEventListener('click', function (event) {
    if (event.target == menuButtonMobile || event.target.parentElement == menuButtonMobile) {
        return;
    }
    var isMenuOpen = document.querySelector('.resposivemenu-primary').parentElement.classList.contains("showonmobile");
    if (!isMenuOpen) {
        return;
    }
    if (responsiveMobileMenu.contains(event.target)) {

    } else {
        responsiveMobileMenu.parentElement.classList.remove("showonmobile");
        responsiveMobileMenu.parentElement.classList.remove("active");
    }
});

/*
function acceptCookie() {
    document.cookie = "cookieaccepted=1; max-age=31536000; path=/";
    document.getElementById("cookie-notice").style.visibility = "hidden";
}

document.addEventListener('DOMContentLoaded', function () {
    if (document.cookie.indexOf("cookieaccepted") < 0) {
        document.getElementById("cookie-notice").style.visibility = "visible";
    }
}, false);
    */

function loadjQuery(e, t) {
    var n = document.createElement("script");
    n.setAttribute("src", e);
    n.onload = t;
    n.onreadystatechange = function () {
        if (this.readyState == "complete" || this.readyState == "loaded") t()
    };
    document.getElementsByTagName("head")[0].appendChild(n)
}

function main() {
    var $cr = jQuery.noConflict();
    var old_src;
    $cr(document).ready(function () {
        $cr(".cr_form").submit(function () {
            $cr(this).find('.clever_form_error').removeClass('clever_form_error');
            $cr(this).find('.clever_form_note').remove();
            $cr(this).find(".musthave").find('input, textarea').each(function () {
                if (jQuery.trim($cr(this).val()) == "" || ($cr(this).is(':checkbox')) || ($cr(this).is(':radio'))) {
                    if ($cr(this).is(':checkbox') || ($cr(this).is(':radio'))) {
                        if (!$cr(this).parents(".cr_ipe_item").find(":checked").is(":checked")) {
                            $cr(this).parents(".cr_ipe_item").addClass('clever_form_error')
                        }
                    } else {
                        $cr(this).addClass('clever_form_error')
                    }
                }
            });
            if ($cr(this).attr("action").search(document.domain) > 0 && $cr(".cr_form").attr("action").search("wcs") > 0) {
                var cr_email = $cr(this).find('input[name=email]');
                var unsub = false;
                if ($cr("input['name=cr_subunsubscribe'][value='false']").length) {
                    if ($cr("input['name=cr_subunsubscribe'][value='false']").is(":checked")) {
                        unsub = true
                    }
                }
                if (cr_email.val() && !unsub) {
                    $cr.ajax({
                        type: "GET",
                        url: $cr(".cr_form").attr("action").replace("wcs", "check_email") + window.btoa($cr(this).find('input[name=email]').val()),
                        success: function (data) {
                            if (data) {
                                cr_email.addClass('clever_form_error').before("<div class='clever_form_note cr_font'>" + data + "</div>");
                                return false
                            }
                        },
                        async: false
                    })
                }
                var cr_captcha = $cr(this).find('input[name=captcha]');
                if (cr_captcha.val()) {
                    $cr.ajax({
                        type: "GET",
                        url: $cr(".cr_form").attr("action").replace("wcs", "check_captcha") + $cr(this).find('input[name=captcha]').val(),
                        success: function (data) {
                            if (data) {
                                cr_captcha.addClass('clever_form_error').after("<div  class='clever_form_note cr_font'>" + data + "</div>");
                                return false
                            }
                        },
                        async: false
                    })
                }
            }
            if ($cr(this).find('.clever_form_error').length) {
                return false
            }
            return true
        });
        $cr('input[class*="cr_number"]').change(function () {
            if (isNaN($cr(this).val())) {
                $cr(this).val(1)
            }
            if ($cr(this).attr("min")) {
                if (($cr(this).val() * 1) < ($cr(this).attr("min") * 1)) {
                    $cr(this).val($cr(this).attr("min"))
                }
            }
            if ($cr(this).attr("max")) {
                if (($cr(this).val() * 1) > ($cr(this).attr("max") * 1)) {
                    $cr(this).val($cr(this).attr("max"))
                }
            }
        });
        old_src = $cr("div[rel='captcha'] img:not(.captcha2_reload)").attr("src");
        if ($cr("div[rel='captcha'] img:not(.captcha2_reload)").length != 0) {
            captcha_reload()
        }
    });

    function captcha_reload() {
        var timestamp = new Date().getTime();
        $cr("div[rel='captcha'] img:not(.captcha2_reload)").attr("src", "");
        $cr("div[rel='captcha'] img:not(.captcha2_reload)").attr("src", old_src + "?t=" + timestamp);
        return false
    }

}

if (typeof jQuery === "undefined") {
    loadjQuery("//ajax.googleapis.com/ajax/libs/jquery/1.4.2/jquery.min.js", main)
} else {
    main()
}
'use strict';

function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
        for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
            arr2[i] = arr[i];
        }
        return arr2;
    } else {
        return Array.from(arr);
    }
}

var CookieService = {
    setCookie: function setCookie(name, value, days) {
        var expires = '';

        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            expires = '; expires=' + date.toUTCString();
        }

        document.cookie = name + '=' + (value || '') + expires + ';';
    },
    getCookie: function getCookie(name) {
        var cookies = document.cookie.split(';');

        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
            for (var _iterator = cookies[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                var cookie = _step.value;

                if (cookie.indexOf(name + '=') > -1) {
                    return cookie.split('=')[1];
                }
            }
        } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
        } finally {
            try {
                if (!_iteratorNormalCompletion && _iterator.return) {
                    _iterator.return();
                }
            } finally {
                if (_didIteratorError) {
                    throw _iteratorError;
                }
            }
        }

        return null;
    }
};
var exit = function exit(e) {
    var shouldExit = [].concat(_toConsumableArray(e.target.classList)).includes('exit-intent-popup') || // user clicks on mask
        e.target.className === 'close' || // user clicks on the close icon
        e.keyCode === 27; // user hits escape

    if (shouldExit) {
        document.querySelector('.exit-intent-popup').classList.remove('visible');
    }
};

var mouseEvent = function mouseEvent(e) {
    var shouldShowExitIntent = !e.toElement && !e.relatedTarget && e.clientY < 10;

    if (shouldShowExitIntent) {
        document.removeEventListener('mouseout', mouseEvent);
        document.querySelector('.exit-intent-popup').classList.add('visible');

        CookieService.setCookie('exitIntentShown', true, 30);
    }
};

if (!CookieService.getCookie('exitIntentShown')) {
    setTimeout(function () {
        document.addEventListener('mouseout', mouseEvent);
        document.addEventListener('keydown', exit);
        document.querySelector('.exit-intent-popup').addEventListener('click', exit);
    }, 0);
}